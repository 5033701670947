<template>
  <section class="top-creator-section" :class="classname">
    <div class="container">
      <div class="section-head-sm" :class="isCenter">
        <h2>Last Deposit</h2>
      </div>
      <!-- creators -->
      <!--      <Creators></Creators>-->

      <swiper
          :slides-per-view="3"
          :breakpoints="{
              0: {
                  slidesPerView: 1
              },
              767: {
                  slidesPerView: 2
              },
              992: {
                  slidesPerView: 2
              },
              1200: {
                  slidesPerView: 3
              }
            }"
          :pagination="{ clickable: true }"
          :autoplay="{ delay: 3000, disableOnInteraction: false }">
        <swiper-slide v-for="(dep) in LastDepositList" :key="dep.id">
          <div class="card card-creator-s1">
            <div class="card-body">
              <!--                        <img :src="item.img" alt="avatar">-->
              <img src="@/images/tmp/people.png" alt="avatar" width="64">
              <div class="flex-grow-1">
                <span style="margin-left: 5px;">{{ dep.email }}</span>
                <!--                <router-link :to="item.path" class="text-black fw-semibold">{{ item.title }}</router-link>-->
                <span class="d-block fw-medium" style="margin-left: 5px;color:#0d6efd;"> {{
                    dep.amount.toFixed(4)
                  }} {{ dep.coinType }}</span>
              </div>
            </div>
          </div><!-- end card -->
        </swiper-slide>
      </swiper>


      <div class="section-head-sm mt-3" :class="isCenter">
        <h2>Last Withdraw</h2>
      </div>

      <swiper
          :slides-per-view="3"
          :breakpoints="{
              0: {
                  slidesPerView: 1
              },
              767: {
                  slidesPerView: 2
              },
              992: {
                  slidesPerView: 2
              },
              1200: {
                  slidesPerView: 3
              }
            }"
          :pagination="{ clickable: true }"
          :autoplay="{ delay: 3000, disableOnInteraction: false }">
        <swiper-slide v-for="(item) in LastWithdrawList" :key="item.id">
          <div class="card card-creator-s1">
            <div class="card-body">
              <img src="@/images/tmp/people.png" alt="avatar" width="64">
              <div class="flex-grow-1">
                <span style="margin-left: 5px;">{{ item.email }}</span>
                <span v-if="item.type===-1" class="d-block fw-medium"
                      style="margin-left: 5px;color:#0d6efd;"> - {{ item.amount.toFixed(4) }} {{ item.coinType }}</span>
                <span v-if="item.type!==-1" class="d-block fw-medium"
                      style="margin-left: 5px;color:#0d6efd;"> {{ item.amount.toFixed(4) }} {{ item.coinType }}</span>
              </div>
            </div>
          </div><!-- end card -->
        </swiper-slide>
      </swiper>

    </div><!-- .container -->
  </section><!-- end top-creator-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import Creators from "@/components/n1/Creators"
import indexApi from "@/apis/indexApi"
import {Swiper, SwiperSlide} from "swiper/vue";
import ProductsItem from "@/components/section/ProductsItem.vue";
// import storage from "@/storage";

export default {
  name: 'TopCreators',
  props: ['classname', 'isCenter'],
  components: {
    ProductsItem, SwiperSlide, Swiper,
    Creators

  },
  data() {
    return {
      SectionData,
      LastDepositList: [],
      LastWithdrawList: [],
    }
  },
  created() {
    indexApi.lTrans(res => {
      if (res.code !== 200) {
        // storage.localDel("userId")
        // this.login = false
      } else {
        this.LastDepositList = res.data.lastDepositList
        this.LastWithdrawList = res.data.lastWithdrawList
      }
    })

  }
}
</script>
