<template>
  <swiper ref="mySwiper" :modules="modules"
          :slides-per-view="1"
          :autoplay="{delay: 4000}"
          :effect="'fade'"
          :loop="true"
          :navigation="{ clickable: true }" class="swiper-button-s1 swiper-button-s2 on-dark no-animated">
    <swiper-slide v-for="item in heroDataEight" :key="item.id" class="h-auto">
      <div class="slider-full-screen set-bg" :data-set-bg="item.img">
        <div class="row">
          <div class="slider-full-body col-lg-6 col-md-8 col-sm-9">
            <h2 class="mb-3 card-title">{{ item.title }}</h2>
            <p class="mb-4 card-text">{{ item.content }}</p>
            <ul class="btns-group hero-btns">
              <li>
                <a href="/sign-up" class="btn btn-lg btn-outline-dark">
                  SIGN UP
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import SectionData from '@/store/store.js'
import SwiperCore, { Navigation, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

SwiperCore.use([Navigation, EffectFade]);

export default {
  name: 'FullScreenSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      SectionData,
      heroDataEight: [
        {
          id: 1,
          img: require('@/images/tmp/banner2.jpg'),
          title: "Welcome to GDMining, Where Efficiency Meets Profitability",
          content: 'Founded in 2021, UK, we\'re the top cloud mining platform worldwide. Start mining today in three simple steps, withdraw your first earnings tomorrow.'
        },
        {
          id: 2,
          img: require('@/images/tmp/banner1.png'),
          title: "Sign up bonus $50",
          content: 'Participate in free cloud mining and earn $1 every day for free. The contract profit is fixed and settled every 24 hours. The purchase amount will be refunded upon contract expiration.'
        },
        // {
        //   id: 3,
        //   img: require('@/images/tmp/banner3.png'),
        //   title: "Discover, collect, and sell extraordinary NFT's Assets",
        //   content: 'Securely buy and sell unique digital collectibles. Lowest transaction costs across all NFT markets guaranteed.'
        // },
      ],
    }
  },
  mounted() {
    function setBg(selector) {
      const list = document.querySelectorAll(selector);
      for (let i = 0; i < list.length; i++) {
        const src = list[i].getAttribute('data-set-bg');
        list[i].style.backgroundImage = "url('" + src + "')";
      }
    }

    setBg('.set-bg');
  },
  setup() {
    return {
      modules: [Navigation, EffectFade]
    }
  },
}
</script>
