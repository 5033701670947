<template>
  <metainfo></metainfo>
  <div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
      <!-- Header main -->
      <HeaderMain isTransparent="is-transparent"></HeaderMain>
      <!-- hero -->
      <!--            <HeroTwo></HeroTwo>-->
    </header>

    <!--    <TopGlobalTip></TopGlobalTip>-->
    <div id="promotions-popup"
         @click="handleClick" v-if="promotionsShow"
         class="modal fade show" style="display: block;" tabindex="-1" aria-modal="true" role="dialog">
      <div v-if="alert.type===1" class="modal-dialog modal-dialog-centered modal-xl">
        <img :class="[sharkFlag ?'my-shark' :'']"
             :src="alert.presignedUrl"
             alt=""
             ref="image"/>
      </div>
    </div>

    <div class="main-content">
      <HeroEight></HeroEight>
      <HowItWorkTwo></HowItWorkTwo>
      <section class="section-space trending-section" id="target-contracts">
        <div class="container">
          <!-- section heading -->
          <div class="section-head text-center">
            <h2 class="mb-3">Trending Contracts</h2>
            <p>Multiple meticulously designed cloud mining investment schemes ensure your stable and reliable investment
              returns.</p>
          </div><!-- end section-head -->
          <!-- product -->
          <ProductsContainerSix></ProductsContainerSix>
          <div class="text-center mt-4 mt-md-5">
            <router-link class="btn-link btn-link-s1" to="/contracts"> View all contracts</router-link>
          </div>
        </div><!-- .container -->
      </section><!-- trending-section -->

      <!-- Featured  -->
      <!--      <Featured></Featured>-->


      <section class="about-section section-space-b">
        <div class="container">
          <div class="row align-items-center flex">
            <div class="col-lg-6 mb-5 mb-lg-0"><img src="@/images/tmp/banner1-3.jpg" alt="" class="w-100 rounded-3">
            </div>
            <div class="col-lg-6 ps-xl-5">
              <div class="about-content-wrap"><h2 class="mb-3">About GDMining</h2>
<!--                <p class="lead">EnftyMart was founded with a very simple mission - to make Enfties accessible to-->
<!--                  everyone</p>-->
                <p>GDMining is a leading cloud mining platform established in 2021. We are committed to providing users with an efficient and seamless way to mine cryptocurrencies, ensuring accessibility for both beginners and experienced miners.</p>
                <p>Utilizing state-of-the-art technology, GDMining eliminates the need for expensive hardware or complex technical setups. This allows users to mine Bitcoin and other popular cryptocurrencies with ease, regardless of their location or expertise level.</p>
                <p>Transparency and security are core values at GDMining. We offer real-time performance tracking, robust encryption, and advanced security protocols to protect users' assets and data.</p>
                <p>With flexible mining plans designed to suit a range of investment needs, GDMining empowers users to participate in the growing digital economy and maximize their returns in the cryptocurrency market.</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      <!--    AboutUs-->
      <AboutUs classname="col-lg-3" :title="SectionData.howItWorkData.title"
               :subtitle="SectionData.howItWorkData.content"
               gutterBottom="mb-3"></AboutUs>

      <!-- HowItWork  -->
      <!--              <HowItWork classname="col-lg-3" :title="SectionData.howItWorkData.title" :subtitle="SectionData.howItWorkData.content" gutterBottom="mb-3"></HowItWork>-->
      <!--      <section class="section-space" style="background: #F7F9FB;">-->

      <!--        <div class="container">-->
      <!--          &lt;!&ndash;        <SectionHeading classname="text-center" text="Our Partners" content="Why us? What do we offer? Introduction text placeholderWhy us? What do we offer? Introduction text placeholder" isMargin="mb-3"></SectionHeading>&ndash;&gt;-->
      <!--          <SectionHeading classname="text-center" text="OUR PARTNERS" isMargin="mb-3"></SectionHeading>-->

      <!--        </div>-->
      <!--        &lt;!&ndash;      OurPartners&ndash;&gt;-->
      <!--        <OurPartners></OurPartners>-->
      <!--      </section>-->

      <!-- top creators -->
      <TopCreators classname="section-space-t"></TopCreators>


      <section class="about-section pt-5 mt-3">
        <div class="container">
          <div class="row align-items-center flex flex-lg-row-reverse">
            <div class="col-lg-6 mb-5 mb-lg-0 col-sm-9 ps-xl-5"><img src="@/images/tmp/banner1-5.jpg" alt=""
                                                                     class="w-100 rounded-3"></div>
            <div class="col-lg-6 pe-lg-5">
              <div class="about-content-wrap"><h2 class="mb-3">Affiliate Program Offers A Variety Of Benefits</h2>
                <p>
                  <img class="checkmark" src="@/images/tmp/checkmark.png" alt="checkmark">
                  You can become an affiliate and take advantage of our program even without making a deposit.</p>
                <p>
                  <img class="checkmark" src="@/images/tmp/checkmark.png" alt="checkmark">
                  As a referrer, you are eligible to receive a 3% bonus based on their initial investment amount.</p>
                <p>
                  <img class="checkmark" src="@/images/tmp/checkmark.png" alt="checkmark">
                  We provide instant payouts of referral rewards in cryptocurrency, which are credited to your account balance.</p>
                <p>
                  <img class="checkmark" src="@/images/tmp/checkmark.png" alt="checkmark">
                  There are no limits to the number of referrals you can bring in, so your earning potential is boundless.</p>
              </div>
            </div>
            <ul class=" hero-btns">
              <li><a href="/affiliates" class="btn btn-lg btn-outline-dark">Know More</a></li>
            </ul>
          </div>
        </div>
      </section>


      <section class="brand-section section-space">
        <div class="container">
          <div class="section-head text-center"><h2 class="mb-3">Backed by top firms &amp; industry leaders</h2>
            <p>Who are helping us pave the way towards a brand new digital economy</p></div>

          <div class="row g-gs mt-3">
            <swiper
                :slides-per-view="3"
                :breakpoints="{
              0: {
                  slidesPerView: 2
              },
              575: {
                  slidesPerView: 2
              },
              767: {
                  slidesPerView: 3
              },
              992: {
                  slidesPerView: 4
              },
              1200: {
                  slidesPerView: 4
              }
            }"
                :pagination="{ clickable: true }"
                :autoplay="{ delay: 5000, disableOnInteraction: false }">
              <swiper-slide>
                <div class="client-logo-item text-center">
                  <img src="@/assets/images/partner/111.png" alt="" class="img-fluid">
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="client-logo-item text-center">
                  <img src="@/assets/images/partner/222.png" alt="" class="img-fluid">
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="client-logo-item text-center">
                  <img src="@/assets/images/partner/333.png" alt="" class="img-fluid">
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="client-logo-item text-center">
                  <img src="@/assets/images/partner/444.png" alt="" class="img-fluid">
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="client-logo-item text-center">
                  <img src="@/assets/images/partner/555.png" alt="" class="img-fluid">
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="client-logo-item text-center">
                  <img src="@/assets/images/partner/666.png" alt="" class="img-fluid">
                </div>
              </swiper-slide>

            </swiper>

            <!--            <div class="col-6 col-md-3 col-sm-4">-->
            <!--              <div class="client-logo-item text-center"><img src="@/assets/images/partner/111.png" alt="" class="img-fluid"></div>-->
            <!--            </div>-->
            <!--            <div class="col-6 col-md-3 col-sm-4">-->
            <!--              <div class="client-logo-item text-center"><img src="@/assets/images/partner/222.png" alt="" class="img-fluid">-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="col-6 col-md-3 col-sm-4">-->
            <!--              <div class="client-logo-item text-center"><img src="@/assets/images/partner/333.png" alt="" class="img-fluid">-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="col-6 col-md-3 col-sm-4">-->
            <!--              <div class="client-logo-item text-center"><img src="@/assets/images/partner/444.png" alt="" class="img-fluid">-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="col-6 col-md-3 col-sm-4">-->
            <!--              <div class="client-logo-item text-center"><img src="@/assets/images/partner/555.png" alt="" class="img-fluid">-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="col-6 col-md-3 col-sm-4">-->
            <!--              <div class="client-logo-item text-center"><img src="@/assets/images/partner/666.png" alt="" class="img-fluid">-->
            <!--              </div>-->
            <!--            </div>-->
          </div>


        </div>
      </section>

      <section class="section-space">
        <div class="container">
          <SectionHeading classname="text-center" text="Support Payment Methods" isMargin="mb-3"></SectionHeading>
          <PayJoinSection></PayJoinSection>
        </div>
      </section>

      <!-- Blog  -->
      <!--      <section class="blog-section">-->
      <!--        <div class="container">-->
      <!--          &lt;!&ndash; section heading &ndash;&gt;-->
      <!--          <SectionHeading classname="text-center" :text="SectionData.blogData.title"-->
      <!--                          :content="SectionData.blogData.content" isMargin="mb-3"></SectionHeading>-->
      <!--          &lt;!&ndash; blog section &ndash;&gt;-->
      <!--          <BlogSectionSlider></BlogSectionSlider>-->
      <!--          &lt;!&ndash;          <div class="text-center mt-5">&ndash;&gt;-->
      <!--          &lt;!&ndash;            <ButtonLink :text="SectionData.blogData.btnText" link="/blog"&ndash;&gt;-->
      <!--          &lt;!&ndash;                        classname="btn-link btn-link-s1"></ButtonLink>&ndash;&gt;-->
      <!--          &lt;!&ndash;          </div>&ndash;&gt;-->
      <!--        </div>&lt;!&ndash; .container &ndash;&gt;-->
      <!--      </section>&lt;!&ndash; end blog-section &ndash;&gt;-->

      <section class="mb-5">
        <SectionHeading classname="text-center" text="Frequently Asked Questions" isMargin="mb-3"></SectionHeading>
        <div class="accordion" id="qaAccordion">
          <div class="accordion-item" v-for="(item, index) in questions" :key="index">
            <h2 class="accordion-header" :id="'heading-' + index">
              <button
                  class="accordion-button bg-light text-dark custom-accordion-btn"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse-' + index"
                  aria-expanded="false"
                  :aria-controls="'collapse-' + index"
              >
                {{ item.question }}
              </button>
            </h2>
            <div
                :id="'collapse-' + index"
                class="accordion-collapse collapse"
                :aria-labelledby="'heading-' + index"
                data-bs-parent="#qaAccordion"
            >
              <div class="accordion-body bg-white text-muted">
                {{ item.answer }}
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
    <!-- Footer  -->
    <Footer classname="bg-cus on-dark"></Footer>
  </div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import HeroTwo from '@/components/n1/HeroTwo.vue'
import AboutUs from "@/components/n1/AboutUs";
import OurPartners from "@/components/n1/OurPartners";
import SeoSection from "@/components/n1/SeoSection";
import PayJoinSection from "@/components/n1/PayJoinSection"
import {useMeta} from 'vue-meta'
import homeApi from "@/apis/homeApi"
import HeroEight from "@/components/n1/HeroEight.vue"
import ProductsContainer from "@/components/n1/ProductsContainer"
import HeaderMain from "@/components/n1/HeaderMain"
import HowItWorkTwo from "@/components/n1/HowItWorkTwo"
import ProductsContainerSix from "@/components/n1/ProductsContainerSix"
import Footer from "@/pages/n1/Footer"
import SectionHeading from "@/components/n1/SectionHeading"
import BlogSectionSlider from "@/components/n1/BlogSectionSlider"
import TopCreators from "@/components/n1/TopCreators"
import {Swiper, SwiperSlide} from "swiper/vue";

export default {
  name: 'Home',
  components: {
    SwiperSlide, Swiper,
    HeroEight,
    HeroTwo, AboutUs, OurPartners, SeoSection, PayJoinSection,
    ProductsContainerSix,
    ProductsContainer,
    HeaderMain,
    HowItWorkTwo,
    Footer,
    SectionHeading,
    BlogSectionSlider,
    TopCreators
  },
  setup() {
    useMeta({
      title: 'Cloud Mining Services | Cloud Mining | GDMining',
      description: 'Discover high-quality cryptocurrency cloud mining services at GDMining, empowering your digital assets with secure and effective mining solutions.',
      link: [
        {rel: 'canonical', href: 'https://gdmining.com/'}
      ],
      htmlAttrs: {lang: 'en', amp: true}
    })
  },
  created() {
    homeApi.notification(res => {
      if (res.data.ifAlert === true) {
        this.promotionsShow = true
      }
      this.alert = res.data
    })
  },
  mounted() {
    setTimeout(() => {
      this.sharkFlag = true;
    }, 2000)
  },
  methods: {
    handleClick(event) {
      // if (event.target.id === 'promotions-popup') {
      //   this.promotionsShow = false;
      // }
      const rect = this.$refs.image.getBoundingClientRect();
      const x = event.clientX;
      const y = event.clientY;
      if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
        this.promotionsShow = false;
        this.$nextTick(() => {
          const target = document.getElementById('target-contracts');
          target.scrollIntoView({
            behavior: 'smooth'
          });
        });
      }
    },
  },
  data() {
    return {
      questions: [
        {
          question: "What is Cloud Mining?",
          answer: "Cloud mining is a method of cryptocurrency mining where individuals lease computing power from remote data centers, eliminating the need to purchase and maintain hardware. Miners rent hashing power from third-party providers to participate in mining, and rewards are proportionate to the rented computing capacity."
        },
        {
          question: "How do i get started with GDMining?",
          answer: "Register for a free membership account using your email address, then top up your account, choose mining contracts you want to invest in, and start earning daily returns!"
        },
        {
          question: "What are GDMining Advantages?",
          answer: "Join GDMining, where you don't need hardware/software/technical expertise, nor do you need to research cloud mining trends or market fluctuations. Our experienced investment team and IT experts will provide you with the best market-leading technology. Simply select the Cloud Mining contracts you need to invest in. Even if you're a beginner, you can get started quickly and benefit from it."
        },
        {
          question: "Can I buy more than one Cloud Mining contract?",
          answer: "Yes, you can buy multiple contracts, even contracts of different terms."
        },
        {
          question: "When are rewards paid out?",
          answer: "The rewards are paid out daily and withdrawable anytime."
        },
        {
          question: "Do I need to pay electricity and maintenance costs?",
          answer: "No, no extra fees. The electricity and maintenance costs are included in the contract cost."
        },
        {
          question: "Is there a minimum or maximum purchase amount?",
          answer: "Minimum purchase amounts differs for every contract, you may find it on our pricing page."
        },
        {
          question: "Is there any minimum withdrawal amount?",
          answer: "Minimum withdrawal is $200."
        },
        {
          question: "What payment methods do you support?",
          answer: "You can pay for mining contracts using cryptocurrencies such as BTC,ETH,LTC,USDT."
        },
        {
          question: "What is the affiliates program?",
          answer: "Every GDMining customer has a unique referral link, which can be shared with anyone. Copy your referral link and share it to get bonuses. You can start earning money even if you do not invest. As a referrer, you are eligible to receive a 3% bonus based on their initial investment amount. For example, if someone signs up using your referral code and deposits $1000, you'll receive $30 for free."
        }

      ],
      sharkFlag: false,
      promotionsShow: false,
      alert: {
        content: "",
        presignedUrl: null,
        ifAlert: false,
        type: 2
      },
      SectionData,
      productData: {
        title: 'CHOOSE MINING CONTRACTS',
        content: 'We offer a variety of cloud mining investment contracts, ensuring stable and reliable profits.',
        // contentTwo: "Explore on the world's best NFT marketplace with our beautiful NFT products. We want to be a part of your smile.",
        // btnText: 'View all auctions',
        // btnLink: '/explore',
        // btnLinkTwo: '/explore-v3',
        // btnLinkThree: '/explore-v4',
        // btnLinkFour: '/explore-v5',
        // btnLinkFive: '/explore-v6',
        // btnTextTwo: 'Load More',
        // meta: 'NTFs Products',
      }
    }
  }
}
</script>

<style>

.bg-cus {
  background-color: #ffe5d0;
}


.client-logo-item {
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 2.1rem;
  padding: 0;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
//height: 150px;
}

.client-logo-item img {
  max-width: 80%;
  height: auto;
}

.main-content {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}


#promotions-popup {
  .modal-dialog-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  .my-shark {
  //animation:login-shake 0.8s 1 ease-in; animation: login-shake 0.8s 2 ease-in forwards;
  }

  @keyframes login-shake {
    0% {
      transform: scale(1);
    }
    10%, 20% {
      transform: scale(0.9) rotate(-3deg);
    }
    30%, 50%, 70%, 90% {
      transform: scale(1.1) rotate(3deg);
    }
    40%, 60%, 80% {
      transform: scale(1.1) rotate(-3deg);
    }
    100% {
      transform: scale(1) rotate(0);
    }
  }
}


.testimonial {
  h2 {
    text-align: center;
  }

}

.swiper-button-prev,
.swiper-button-next {
//display: none;
}

.section-space, .blog-section {
  padding: 50px 0;
}

.section-space-t, .section-space {
  padding-top: 20px;
}

@media (min-width: 576px) {
  .hero-slider-wrap {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}


.custom-accordion-btn {
  border: 1px solid #ffe5d0;
  border-radius: 0.25rem;
  background-color: #f8f9fa;
  transition: background-color 0.3s ease;
}

.custom-accordion-btn:hover {
  background-color: #e2e6ea;
}

.accordion-item {
  margin-bottom: 0.36rem;
}

.accordion-body {
  font-size: 0.95rem;
  padding: 1.25rem;
  background-color: #ffffff;
  border-top: 1px solid #dee2e6;
}

.accordion-button:not(.collapsed) {
  background-color: #e9ecef;
  color: #495057;
}

.checkmark {
  width: 36px;
}


.about-content-wrap {
  color: #0a0a0a;
}
</style>
