<template>
    <div class="hero-wrap hero-wrap-2 section-space">
        <div class="container">
            <div class="row align-items-center flex-md-row-reverse justify-content-between">
                <div class="col-lg-6  col-md-6">
                    <BannerSlider :heroData="heroData.banners || []"></BannerSlider>
                </div><!-- end col-lg-5 -->
                <div class="col-lg-6 col-md-6">
                    <div class="hero-content pb-0 pt-md-0 pe-lg-4">
                        <h1 class="hero-title mb-4">{{ heroData.titleBefore }}
                          <span class="nfts-word">{{ heroData.title }}</span>
                          {{ heroData.titleAfter }}
                        </h1>
                        <p class="hero-text mb-4 pb-1">{{ heroData.content }}</p>
                        <p class="hero-text mb-4 pb-1">{{ heroData.content2 }}</p>

                      <div class="download" style="opacity: 1;">
                        <div>
                          <p>Download the</p>
                          <p>GDMining app</p>
                          <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 8" class="sc-aXZVg cVRlXL">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 21" class="sc-aXZVg ebEdMx">
                                <path d="M17.4526 7.32173C17.3571 7.37744 15.0838 8.5531 15.0838 11.1598C15.191 14.1326 17.9526 15.1752 18 15.1752C17.9526 15.2309 17.5831 16.5954 16.4883 18.0257C15.6196 19.2578 14.6553 20.5 13.191 20.5C11.7981 20.5 11.2981 19.6788 9.69097 19.6788C7.96504 19.6788 7.47668 20.5 6.15525 20.5C4.69096 20.5 3.65524 19.1912 2.73909 17.9707C1.54887 16.3732 0.537222 13.8664 0.501508 11.4593C0.47744 10.1838 0.739862 8.93004 1.40601 7.86507C2.34623 6.37831 4.02481 5.36905 5.85789 5.33577C7.26239 5.29164 8.5124 6.23434 9.36954 6.23434C10.191 6.23434 11.7267 5.33577 13.4643 5.33577C14.2143 5.33649 16.2143 5.54703 17.4526 7.32173ZM9.25075 5.0811C9.00075 3.91629 9.69097 2.75148 10.3338 2.00846C11.1553 1.1099 12.4526 0.5 13.5714 0.5C13.6428 1.66481 13.1902 2.80719 12.3812 3.6392C11.6553 4.53777 10.4053 5.21422 9.25075 5.0811Z"></path>
                              </svg>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 20" class="sc-aXZVg cVRlXL">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 21" class="sc-aXZVg ebEdMx">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.83965 1.18034C6.61554 0.746974 7.5097 0.5 8.46154 0.5C9.41338 0.5 10.3075 0.746974 11.0834 1.18034L11.6511 0.612651C11.8013 0.46245 12.0448 0.46245 12.195 0.612651C12.3452 0.762853 12.3452 1.00638 12.195 1.15658L11.7394 1.61227C13.0204 2.5966 13.8462 4.14418 13.8462 5.88462H3.07692C3.07692 4.14418 3.90265 2.5966 5.18372 1.61227L4.72804 1.15658C4.57783 1.00638 4.57783 0.762853 4.72804 0.612651C4.87824 0.46245 5.12176 0.46245 5.27196 0.612651L5.83965 1.18034ZM6.92308 3.57692C6.92308 4.00176 6.57868 4.34615 6.15385 4.34615C5.72901 4.34615 5.38462 4.00176 5.38462 3.57692C5.38462 3.15209 5.72901 2.80769 6.15385 2.80769C6.57868 2.80769 6.92308 3.15209 6.92308 3.57692ZM10.7692 4.34615C11.1941 4.34615 11.5385 4.00176 11.5385 3.57692C11.5385 3.15209 11.1941 2.80769 10.7692 2.80769C10.3444 2.80769 10 3.15209 10 3.57692C10 4.00176 10.3444 4.34615 10.7692 4.34615Z"></path>
                                <path d="M0 7.80769C0 7.17044 0.516594 6.65385 1.15385 6.65385C1.7911 6.65385 2.30769 7.17044 2.30769 7.80769V12.4231C2.30769 13.0603 1.7911 13.5769 1.15385 13.5769C0.516594 13.5769 0 13.0603 0 12.4231V7.80769Z"></path><path d="M5.38462 16.6538V19.3462C5.38462 19.9834 5.90121 20.5 6.53846 20.5C7.17571 20.5 7.69231 19.9834 7.69231 19.3462V16.6538H9.23077V19.3462C9.23077 19.9834 9.74736 20.5 10.3846 20.5C11.0219 20.5 11.5385 19.9834 11.5385 19.3462V16.6538H12.3077C13.1574 16.6538 13.8462 15.9651 13.8462 15.1154V6.65385H3.07692V15.1154C3.07692 15.9651 3.76572 16.6538 4.61538 16.6538H5.38462Z"></path><path d="M14.6154 7.80769C14.6154 7.17044 15.132 6.65385 15.7692 6.65385C16.4065 6.65385 16.9231 7.17044 16.9231 7.80769V12.4231C16.9231 13.0603 16.4065 13.5769 15.7692 13.5769C15.132 13.5769 14.6154 13.0603 14.6154 12.4231V7.80769Z"></path>
                              </svg>
                            </svg>
                          </div>
                        </div><img src="@/images/tmp/download-qr.png" alt="QR Code for Binance.US app download">
                      </div>

                        <!-- button group -->
                        <ButtonGroup :btns="btnDataThree" classname="hero-btns justify-content-md-start justify-content-center"></ButtonGroup>
                    </div><!-- hero-content -->
                </div><!-- col-lg-6 -->
            </div><!-- end row -->
        </div><!-- .container-->
    </div><!-- end hero-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import BannerSlider from '@/components/n1/BannerSlider'
export default {
  name: 'HeroTwo',
  components: {BannerSlider},

  data () {
    return {
      SectionData,
      btnDataThree: [
        {
          btnClass: 'btn-lg btn-dark',
          // title: 'Explore',
          title: 'START MINING',
          // path: '/explore'
          path: '#contracts'
        },
        // {
        //   btnClass: 'btn-lg btn-outline-dark',
        //   title: 'Create',
        //   path: '/create'
        // }
      ],
      heroData: {
        titleBefore: 'EFFICIENT AND STABLE',
        title: 'CLOUD MINING',
        titleAfter: 'INVESTMENT PLATFORM',
        content: 'We features a world-class technical team. Using advanced servers and a robust solar power generation system, we offer stable investment returns for investors with low costs and high profits.',
        content2: 'Begin your cloud mining investment, withdraw your first payout after 24 hours.',
        banners: [
          {
            img: require('@/images/tmp/banner-11.jpg'),
            path: ''
          },
          // {
          //   // img: require('@/images/thumb/nft-img.png'),
          //   // img: require('@/images/tmp/banner.png'),
          //   img: require('@/images/tmp/banner-2.jpg'),
          //   path: ''
          // },
          // {
          //   // img: require('@/images/tmp/banner1.png'),
          //   img: require('@/images/tmp/banner-3.jpg'),
          //   path: ''
          // },
          // {
          //   // img: require('@/images/tmp/banner1.png'),
          //   img: require('@/images/tmp/home2.jpg'),
          //   path: ''
          // },
          // {
          //   img: require('@/images/thumb/nft-img.png'),
          //   path: ''
          // }
        ]
      },
    }
  }
}
</script>

<style lang="scss" scoped>


.download{
  margin-top: 5px;
  margin-bottom: 10px;
  background: rgb(32, 32, 37);
  border-radius: 12px;
  display: inline-flex;

  div{
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 20px;
    p{
      color: rgb(255, 255, 255);
      font-size: 20px;
      font-weight: 600;
      line-height: 125%;
    }
    div{
      margin-top: 15px;
      display: block;

    }
  }

  img{
    width: 100px;
    height: 100px;
    margin: 4px;
    border-radius: 8px;
  }

  .cVRlXL{
    box-sizing: border-box;
    margin-right: 16px;
    min-width: 0px;
    color: rgb(255, 255, 255);
    width: 20px;
    height: 20px;
    font-size: 20px;
    fill: rgb(255, 255, 255);
  }


}


@media (max-width: 991px){
  .hero-wrap-2 {
    padding-top: 30px;
  }
}


@media (min-width: 992px){
  .hero-wrap-2 {
    padding-top: 50px;
  }
}



.hero-btns{
  width: 100%;
}
.nfts-word {
  color: #0099fa;
}

</style>
