<template>
  <div class="nav-tabs-wrap">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <ul class="nav nav-pills nav-pills-s1 mb-1 justify-content-center" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation" v-for="list in tabNav" :key="list.id">
            <router-link class="nav-link" :class="list.isActive" :to="list.link">
              {{ list.title }}
            </router-link>

<!--            <button class="nav-link" :class="list.isActive" :id="list.slug" data-bs-toggle="pill"-->
<!--                    :data-bs-target="list.bsTarget" type="button">-->
<!--              {{ list.title }}-->
<!--            </button>-->
          </li>
        </ul>
      </div><!-- end col -->
<!--      <div class="col-lg-12">-->
<!--        <div class="tab-content" id="pills-tabContent">-->
<!--          <div class="tab-pane show active" id="pills-connect-wallet" role="tabpanel"-->
<!--               aria-labelledby="pills-connect-wallet-tab">-->
<!--            <div class="row align-items-center justify-content-center">-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card-tab-img mb-5 mb-lg-0">-->
<!--                  &lt;!&ndash;                                    <img :src="require('@/images/thumb/tabs01-img.png')" alt="" class="img">&ndash;&gt;-->
<!--                  <img :src="require('@/images/tmp/sign.png')" alt="" class="img">-->
<!--                </div>-->
<!--              </div>&lt;!&ndash; end col &ndash;&gt;-->
<!--              <div class="col-lg-7 ps-lg-5">-->
<!--                <div class="card-tab">-->
<!--                  <h3 class="card-tab-title">Sign up with your email address</h3>-->
<!--                  <p class="card-tab-text">Ensure your email can receive messages properly.</p>-->
<!--                  <ul class="list-item list-item-icon">-->
<!--                    <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> 1-Register successfully and-->
<!--                      get $50 immediately-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                  <router-link class="btn btn-outline-dark" to="/sign-up">Sign up</router-link>-->
<!--                </div>&lt;!&ndash; end card-tab &ndash;&gt;-->
<!--              </div>&lt;!&ndash; end col &ndash;&gt;-->
<!--            </div>&lt;!&ndash; end row &ndash;&gt;-->
<!--          </div>&lt;!&ndash; end tab-pane &ndash;&gt;-->
<!--          <div class="tab-pane" id="pills-create-nft-store" role="tabpanel"-->
<!--               aria-labelledby="pills-create-nft-store-tab">-->
<!--            <div class="row align-items-center justify-content-center">-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card-tab-img mb-5 mb-lg-0">-->
<!--                  &lt;!&ndash;                                    <img :src="require('@/images/thumb/tabs02-img.png')" alt="" class="img">&ndash;&gt;-->
<!--                  <img :src="require('@/images/tmp/contract.png')" alt="" class="img">-->
<!--                </div>-->
<!--              </div>&lt;!&ndash; end col &ndash;&gt;-->
<!--              <div class="col-lg-7 ps-lg-5">-->
<!--                <div class="card-tab">-->
<!--                  <h3 class="card-tab-title">Browse and purchase the contract you desire</h3>-->
<!--                  <p class="card-tab-text">We offer a variety of profitable and risk-free cloud mining contracts.</p>-->
<!--                  <ul class="list-item list-item-icon">-->
<!--                    <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> The contract profit is fixed-->
<!--                      and settled every 24 hours-->
<!--                    </li>-->
<!--                    <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> The purchase amount will be-->
<!--                      refunded upon contract expiration-->
<!--                    </li>-->
<!--                    <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> No risk of losing funds</li>-->
<!--                  </ul>-->
<!--&lt;!&ndash;                  <ButtonLink :text="btnText" link="/wallet" classname="btn btn-outline-dark"></ButtonLink>&ndash;&gt;-->
<!--                </div>&lt;!&ndash; end card-tab &ndash;&gt;-->
<!--              </div>&lt;!&ndash; end col &ndash;&gt;-->
<!--            </div>&lt;!&ndash; end row &ndash;&gt;-->
<!--          </div>&lt;!&ndash; end tab-pane &ndash;&gt;-->
<!--          <div class="tab-pane" id="pills-start-selling" role="tabpanel" aria-labelledby="pills-start-selling-tab">-->
<!--            <div class="row align-items-center justify-content-center">-->
<!--              <div class="col-lg-4">-->
<!--                <div class="card-tab-img mb-5 mb-lg-0">-->
<!--                  &lt;!&ndash;                                    <img :src="require('@/images/thumb/tabs03-img.png')" alt="" class="img">&ndash;&gt;-->
<!--                  <img :src="require('@/images/tmp/earnings.png')" alt="" class="img">-->
<!--                </div>-->
<!--              </div>&lt;!&ndash; end col &ndash;&gt;-->
<!--              <div class="col-lg-7 ps-lg-5">-->
<!--                <div class="card-tab">-->
<!--                  <h3 class="card-tab-title">Withdraw your earnings </h3>-->
<!--                  <p class="card-tab-text">After purchasing the contract, daily profits will be promptly deposited into-->
<!--                    your account.</p>-->
<!--                  <ul class="list-item list-item-icon">-->
<!--                    <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> No fees for withdrawals</li>-->
<!--                    <li><span class="ni ni-check icon icon-circle icon-wbg icon-xs"></span> When withdrawing, you can-->
<!--                      freely choose the cryptocurrency you want-->
<!--                    </li>-->
<!--                  </ul>-->
<!--&lt;!&ndash;                  <ButtonLink :text="btnText" link="/wallet" classname="btn btn-outline-dark"></ButtonLink>&ndash;&gt;-->
<!--                </div>&lt;!&ndash; end card-tab &ndash;&gt;-->
<!--              </div>&lt;!&ndash; end col &ndash;&gt;-->
<!--            </div>&lt;!&ndash; end row &ndash;&gt;-->
<!--          </div>&lt;!&ndash; end &ndash;&gt;-->
<!--        </div>-->
<!--      </div>&lt;!&ndash; end col &ndash;&gt;-->
    </div>
  </div><!-- end nav-tabs-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

export default {
  name: 'Tab',
  data() {
    return {
      tabNav: [
        {
          id: 1,
          isActive: 'active',
          title: '1. SIGN UP',
          link: '/sign-up',
          slug: 'pills-connect-wallet-tab',
          bsTarget: '#pills-connect-wallet'
        },
        {
          id: 2,
          title: '2. CHOOSE THE CONTRACTS',
          link: '/contracts',
          slug: 'pills-create-nft-store-tab',
          bsTarget: '#pills-create-nft-store'
        },
        {
          id: 3,
          title: '3. START EARNING',
          link: '/sign-up',
          slug: 'pills-start-selling-tab',
          bsTarget: '#pills-start-selling'
        },
      ],
      btnText: 'Create Store',
      SectionData
    }
  }
}
</script>