import axios from "axios";
import Axios from "../axios";
// eslint-disable-next-line no-unused-vars
import { toForm, outErr } from "./apiConfig";

export default {
    banner(out) {
        //admin
        axios.get("/api/index/banner/obtention").then(res => {
            out(res)
        })
    },
    notification(out) {
        Axios.get("/api/notification").then(res => {
            out(res)
        }).catch(() => {
            outErr(out)
        })
    },
}
