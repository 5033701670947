<template>
  <div class="brand__logo--section section--padding" >
    <div class="container">
      <swiper
          :slides-per-view="1"
          :loop="true"
          :navigation="false" :pagination="{ clickable: true }"
          :initial-slide="1" class="">
        <swiper-slide v-for="(Partners, index) in imagePartners" :key="index">
<!--          <div class="card">-->
                  <div class="row  row-cols-2  row-cols-sm-2 row-cols-md-4">
                    <div class="col single__brand--logo " style="cursor: pointer" v-for="(it,i) in Partners" :key="i">
                      <img style="width: 100%" class="logo__light" :src="require('../../assets/images/partner/' + it + '.png')">
                    </div>
<!--                  </div>-->
            <!-- end card-body -->
          </div><!-- end card -->
        </swiper-slide>
      </swiper>

    </div>
  </div>
</template>

<script>
// core version + navigation, pagination modules:
import SwiperCore, { Navigation } from 'swiper';

// configure Swiper to use modules
SwiperCore.use([Navigation]);

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

export default {
  name: "OurPartners",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      imagePartners: [],
      imagePartnersAll: [
        // "opensea",
        // "sfermion",
        "BENZINGA",
        "BINANCE",
        // "zokyo",
        "google",
        // "forbes",
        "yahoofinance",
        "youtube",
        // "coinbase",
        // "coinpedia",
        // "AMBCRYPTO",
        // "coingecko",
        "coinmarketcap",
        // "BENZINGA",
        // "CoinGape",
        // "GlobeNewswire",
        // "cryptionews",
        // "AnalyticsInsight",
        // "SOURECFORGE",
        // "MarketWatch",
      ],
      isMobile: false
    }
  },
  created() {
    // const that = this;
    // if (window.innerWidth < 540) {
    //   that.isMobile = true
    // } else {
    //   that.isMobile = false
    // }
  },
  mounted() {
    this.imagePartners = this.dataFormat(this.imagePartnersAll || [], 4)
    // const that = this;
    // window.addEventListener('resize', function ($event) {
    //   // console.log($event)
    //   if (window.innerWidth < 540) {
    //     that.isMobile = true
    //   } else {
    //     that.isMobile = false
    //   }
    // })
  },
  methods: {

    dataFormat (arr, size) {  // arr是一维数组 size是二维数组包含几条数据
      var arr2 = []
      for (var i = 0; i < arr.length; i = i + size) {
        arr2.push(arr.slice(i, i + size))
      }
      return arr2 // 新的二维数组
    }
  },
  unmounted() {
    // window.removeEventListener('resize', function () {
    //   console.log('删除监听')
    // })
  }
}
</script>

<style scoped lang="scss">
//@import '@/style/common.scss';

.brand__logo--section {
  img {
    max-width: 100%;
    height: auto;
    display: block;
    transition: all 0.3s ease 0s;
  }

  .brand__logo--section {
    background: #f9f9f9
  }

  //@media only screen and (max-width: 767px) {
  //  .single__brand--logo {
  //    width: 25%
  //  }
  //}
  //
  //@media only screen and (max-width: 479px) {
  //  .single__brand--logo {
  //    width: 45%
  //  }
  //}

  .single__brand--logo:hover img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05)
  }
}
</style>
