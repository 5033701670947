<template>
<!--  <div class="hero-slider-wrap">-->
<!--    <div class="container">-->
<!--      &lt;!&ndash; Featured Item Slider &ndash;&gt;-->
<!--      <FullScreenSlider></FullScreenSlider>-->
<!--    </div>&lt;!&ndash; .container&ndash;&gt;-->
<!--  </div>&lt;!&ndash; end hero-wrap &ndash;&gt;-->

  <div class="hero-wrap hero-wrap-2 section-space">
    <div class="container">
      <div class="row flex-lg-row-reverse justify-content-between align-items-center">
        <div class="col-lg-6">
          <div id="heroCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="3000">
                <img src="@/images/tmp/banner1-1.jpg" class="d-block w-100" alt="1" loading="lazy">
              </div>
              <div class="carousel-item" data-bs-interval="3000">
                <img src="@/images/tmp/banner1-2.jpg" class="d-block w-100" alt="2" loading="lazy">
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="hero-content pt-lg-0 pb-0 mt-lg-n4">
            <h1 class="hero-title hero-title-s1 mb-3">Welcome To GDMining,
              Where Efficiency Meets Profitability
            </h1>
            <p class="hero-text mb-4 pb-1">Founded in 2021, UK, we're the top cloud mining platform worldwide. Start mining today in three simple steps, withdraw your first earnings tomorrow.</p>
            <ul class="btns-group hero-btns">
              <li><a href="/sign-up" class="btn btn-lg btn-grad">Sign Up</a></li>
              <li><a href="/contracts" class="btn btn-lg btn-outline-dark">Buy Now</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import FullScreenSlider from "@/components/n1/FullScreenSlider"

export default {
  name: 'HeroEight',
  components: {
    FullScreenSlider
  },
  data() {
    return {
      SectionData,
    }
  }
}
</script>
<style>
.carousel-item img {
  border-radius: 12px;
}
.section-space {
  padding: 5px 0;
}

.hero-text {
  color: #0a0a0a;
}
</style>
