/* eslint-disable no-undef */
<template>
    <swiper
    :slides-per-view="1"
    :loop="false"
    :autoplay="{ delay: 3000 }"
    :navigation="{ clickable: true }" :pagination="{ clickable: true }" class="banner-slider swiper-button-s3">
        <swiper-slide v-for="(hero, index) in heroData" :key="index">
            <div class="card">
              <div class="hero-image">
                <img :src="hero.img" alt="" class="w-100">
              </div>
              <!-- end card-body -->
                <router-link
                    v-if="hero.path"
                    class="details"
                    :to="hero.path"
                >
                </router-link>
            </div><!-- end card -->
        </swiper-slide>
    </swiper>
</template>
<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'

// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// configure Swiper to use modules
SwiperCore.use([Navigation, Autoplay]);

// Import Swiper Vue.js components
import { Swiper, SwiperSlide,} from 'swiper/vue';

export default {
  name: 'BannerSlider',
  components: {
      Swiper,
      SwiperSlide,
  },
  props: {
    heroData: {
      default: {}
    }
  },
  data () {
    return {
      SectionData
    }
  },
}

</script>

<style lang="scss" scoped>

.hero-image img {
  border-radius: 20px;
  //overflow: hidden;
}

.banner-slider{
  &.swiper{
    &-container {
      margin: 0;

      &-space {
        .swiper-wrapper {
          padding-bottom: 0 !important;
        }
      }
    }
    ::v-deep &-pagination + .swiper-wrapper {
      padding-bottom: 0 !important;
    }
  }
  ::v-deep .swiper-slide{
    padding: 0;
  }
  ::v-deep  .swiper-pagination + .swiper-wrapper{
    padding-bottom: 0 !important;
  }
}







 .details {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
 }
 .author-link {
   z-index: 2;
   position: relative;
 }
</style>
