<template>
  <section class="section-space how-it-work-section">
    <div class="container">
      <!-- section heading -->
      <SectionHeading classname="text-center" :text="titleThree"></SectionHeading>
      <!-- tab -->
<!--      <Tab></Tab>-->

      <section class="fun-fact-section  section-space">
        <div class="container">
          <div class="row g-gs text-center">
            <div class="col-md-4 col-lg-4 col-sm-6" v-for="item in featuredData.featuredList" :key="item.id">
              <div @click="navigateToPage(item.path)">
                <div class="card card-counter">
                  <div class="card-body card-body-s1">
<!--                    d-none d-md-block -->
                    <img :src="item.img" class="item-img-top " alt="featured image">
                    <h4 class="item-title">
                      {{ item.title }}
                    </h4>
                    <div class="item-content">
                      {{ item.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div><!-- end container -->
  </section><!-- end how-it-work-section -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionHeading from "@/components/n1/SectionHeading"
import Tab from "@/components/n1/Tab"

export default {
  name: 'HowItWorkTwo',
  components: {
    SectionHeading,
    Tab
  },
  data() {
    return {
      titleThree: 'Get Started in 3 simple steps',
      featuredData: {
        title: 'HOW TO START',
        content: 'Here is the text description about the brand introduction.',
        featuredList: [
          {
            id: 1,
            img: require('@/images/tmp/home-icon_1.png'),
            title: '1. Sign Up',
            content: "Fill in the form and get ready to start mining in under than 1 minute.",
            path: '/sign-up'
          },
          {
            id: 2,
            img: require('@/images/tmp/home-icon_2.png'),
            title: '2. Choose Your Contracts',
            content: 'Choose one of the most profitable contracts and start cloud mining.',
            path: '/contracts'
          },
          {
            id: 3,
            img: require('@/images/tmp/home-icon_3.png'),
            title: '3. Start Earning',
            content: 'Withdraw your mining income on a daily basis to your crypto wallet.',
            path: '/profile/deposit'
          },
          // {
          //   id: 4,
          //   img: require('@/images/thumb/art-4.jpg'),
          //   title: 'Tyronejkd Universe',
          //   content: 'After a sold-out Art Blocks drop, Martin is back with',
          //   path: '/item-details'
          // }
        ]
      },
    }
  },
  methods: {
    navigateToPage(path) {
      if (path === '/#contracts') {
        this.scrollToSection('contracts')
      } else {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style scoped>
.section-space, .blog-section {
  padding: 30px 0;
}
.item-img-top {
  width:80px;
}
.item-title {
  margin: 10px 0;
}
</style>