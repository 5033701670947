<template>
  <div class="row g-gs">
    <div class="col-xl-4 col-lg-6 col-sm-6" v-for="(product, index) in products" :key="product.id">
      <ProductsSix :product="product" :index="index" :bitCoin="this.bitCoin" :isRelated="true"></ProductsSix>
<!--      <ProductsItem :product="product" :index="index" :bitCoin="this.bitCoin" :isRelated="true"></ProductsItem>-->
    </div>
    <div class="text-center mt-4 mt-md-5" v-if="showPage">
      <Pagination :records="totalCount" v-model="planForm.pageNum" :per-page="planForm.pageSize"></Pagination>
    </div>
  </div>
</template>

<script>
import ProductsSix from '@/components/n1/ProductsSix'
import Products from '@/components/section/Products'
import ProductsItem from "@/components/section/ProductsItem";
import planApi from "@/apis/planApi";
import Pagination from "v-pagination-3";
import date from "@/date";
import indexApi from "@/apis/indexApi";
import SectionData from "@/store/store";


export default {
  name: 'ProductsContainerSix',
  components: {
    ProductsSix,
    ProductsItem, Products, Pagination
  },
  props: {
    showPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      bitCoin: '1',
      SectionData,
      products: [],
      loading: true,
      planForm: {
        limitTime: '',
        name: '',
        pageNum: 1,
        pageSize: 9
      },
      totalCount: 0,
      totalPages: 1

    }
  },
  watch: {
    'planForm.pageNum': function () {
      this.getTable()
    }
  },
  mounted() {
    this.getTable();
    setInterval(this.getTable, 60000); // 60s
  },
  created() {
    let source = sessionStorage.getItem('source') || ''
    if (source === '' || source === null) {
      const currentURL = window.location.href;
      source = new URL(currentURL).origin;
    }
    indexApi.GetBitcoin(source, res => {
          // alert(res.data)
          this.bitCoin = res.data
        }
    )
  },
  methods: {
    getTable() {
      this.loading = true;
      planApi.list(this.planForm, res => {
        // console.log(res)
        this.loading = false;
        this.products = res.data?.result || [];
        this.totalCount = res.data?.totalCount || 0;
        this.totalPages = res.data?.totalPages || 1;

      })
    },
    getDate(data) {
      return date.getDate(data)
    },
    getTime(data) {
      return date.getTime(data)
    },
    search() {
      this.planForm.pageNum = 1;
      this.totalCount = 0;
      this.totalPages = 0;
      this.products = [];
      this.getTable()
    },
  }

};
</script>